import React, {useEffect, useState} from "react";
import ZTable from "@/components/Table";
import {IColumn} from "@/components/Table/interface";
import Html2Text from "@/components/Html2text";
import {Api} from "@/api";
import {logisticsMap} from "@/pages/order/interface";
import Popover from "@/components/PopOver";
import '../index.less'
import {ISelect} from "@/components/Select/helper";


let group: boolean = true
let flag: string = ''

interface Props {
    specials?: any
    countryMap?: any
    params?: any
    setCountryMap: any
    setCountry?: any
}

const SurchargeList = (props: Props) => {
    const {specials, countryMap = {}, params, setCountryMap, setCountry} = props
    const [data, setData] = useState([])
    const [name, setName] = useState<any>([])
    const [itemsMap, setItemsMap] = useState<Record<string, string[]>>({})


    const init = () => {
        Api.Country.GetWareCountrys().then((res: any) => {
            setCountryMap(res.data)
            const json = Object.keys(res.data).reduce((result: ISelect[], i: string) => {
                result.push({
                    label: res.data[i],
                    value: i
                })
                return result
            }, [])
            setCountry?.(json)
        })
    }


    useEffect(() => {
        init()
    }, [])


    const getData = () => {
        Api.Special.GetSpeAddList(params).then((res: any) => {
            const g = res.data.reduce((result: any, item: any) => {
                if (flag !== item.country) {
                    flag = item.country
                    group = !group
                }
                result.push({
                    ...item,
                    group
                })
                return result
            }, [])
            setData(g)
            let jsonMap = {}
            const json = res.data.reduce((result: string[], i: any) => {
                if (i.parents) {
                    i.parents.forEach((sub: any) => {
                        !result?.includes(sub?.name) && result.push(sub.name)
                        jsonMap[sub.name] = sub.items
                    })
                }
                return result
            }, [])
            setItemsMap(jsonMap)
            if (json?.length) {
                setName([params.name, ...json])
            } else {
                setName([params?.name])
            }
        })
    }

    useEffect(() => {
        if (specials) {
            setData(specials)
        } else {
            getData();
        }

    }, [params, specials]);

    // 产品名称
    const nameRender = (text: string, row: any) => {
        const ids = row.ids?.split('，')
        const items = text?.split('，')?.reduce((result: any[], i: string, index: number) => {
            result[index] = {
                label: i,
                value: ids?.[index]
            }
            return result
        }, [])
        return (
            <div className='flex flex-wrap'>
                {
                    items.map((item: any, index: number) =>
                        <Popover key={index} content={itemsMap[item.label as string] &&
                        <Html2Text data={itemsMap[item.label as string] || item.label} keyword={params?.name}/>}>
                            <div key={item.value} className='mr5 mb5'>
                                <Html2Text data={item.label}
                                           keyword={itemsMap[item.label as string] && name || name[0]}/>、
                            </div>
                        </Popover>
                    )
                }
            </div>
        )
    }


    const column: IColumn[] = [
        {title: '国家', key: 'country', width: 91, render: (text: string) => countryMap[text]},
        {
            title: '物流方式',
            key: 'methods',
            width: 130,
            render: (text: number[]) => text?.map((i: number) => <span key={i} className='mr5'>{logisticsMap[i]}</span>)
        },
        {
            title: '附加费',
            key: 'add',
            width: 100,
            render: (text: number) => !isNaN(Number(text)) && text && <span>￥{text} / kg</span> ||
                <a className='color-2c6ecb' href="tencent://message/?uin=2579788799&Site=&Menu-=yes">{text}</a>
        },
        {title: '产品名称', key: 'names', render: nameRender},
    ]

    return (
        <>
            <ZTable vertical='top' border data={data} column={column}
                    rowClass={(row: any) => row?.group && 'surcharge-odd' || ''}/>
        </>
    )
}

export default SurchargeList